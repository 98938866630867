'use client';
import { IChangePassword, ICredentials, IUserDTO } from '@/lib/models/auth';
import { createContext, useCallback, useContext, useEffect, useState, useTransition } from 'react';
import { protectedRoutes } from '@/lib/auth/protectedRoutes';
import { usePathname, useRouter } from '@/navigation';

interface IAuthHandlerProvider {
  children: React.ReactNode;
}

interface IAuthHandlerContext {
  user: IUserDTO | null;
  loading: boolean;
  // signUp: (userInfo: ISignUpCredentials) => Promise<boolean>;
  signIn: (credentials: ICredentials) => Promise<boolean>;
  signOut: () => Promise<void>;
  changePassword: (changePasswordCredentials: IChangePassword) => Promise<boolean>;
}

const AuthHandlerContext = createContext<IAuthHandlerContext>({} as IAuthHandlerContext);

const AuthHandlerProvider = ({ children }: IAuthHandlerProvider) => {
  const [user, setUser] = useState<IUserDTO | null>(null);
  const [isPending, startTransition] = useTransition();
  const [loading, setLoading] = useState(true);

  const pathname = usePathname() as string;
  const router = useRouter();

  // call the api to sign up
  // const signUp = useCallback(async (userInfo: ISignUpCredentials) => {
  //   try {
  //     const res = await fetch(process.env.NEXT_PUBLIC_URL + '/api/auth', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         type: 'signup',
  //         payload: userInfo,
  //       }),
  //     });

  //     const data = await res.json();

  //     if (!data || data.error) {
  //       return false;
  //     }

  //     return true;
  //   } catch {
  //     console.log('error');
  //     return false;
  //   }
  // }, []);

  // call the api to sign in
  const signIn = useCallback(async (credentials: ICredentials) => {
    try {
      const res = await fetch(process.env.NEXT_PUBLIC_URL + '/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'login',
          payload: credentials,
        }),
      });

      const data = await res.json();

      if (!data || data.error) {
        return false;
      }

      setUser(data);

      return true;
    } catch (e) {
      console.log('error', e);
      return false;
    }
  }, []);

  // call the api to sign out
  const signOut = useCallback(async () => {
    startTransition(async () => {
      try {
        await fetch(process.env.NEXT_PUBLIC_URL + '/api/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type: 'logout',
          }),
        });
        setUser(null);
        if (protectedRoutes.some((route) => pathname.startsWith(route))) {
          router.push('/entrar' + '?next=' + encodeURIComponent(pathname));
        }
      } catch {
        setUser(null);
        if (protectedRoutes.some((route) => pathname.startsWith(route))) {
          router.push('/entrar' + '?next=' + encodeURIComponent(pathname));
        }
      }
    });
  }, [router, pathname]);

  // call the api to change the password
  const changePassword = useCallback(async (changePasswordCredentials: IChangePassword) => {
    try {
      const res = await fetch(process.env.NEXT_PUBLIC_URL + '/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'change-password',
          payload: changePasswordCredentials,
        }),
      });
      if (!res.ok) {
        return false;
      }
      startTransition(async () => {
        await res.json();
      });
      return true;
    } catch (e) {
      console.log('error in changePassword', e);
      return false;
    }
  }, []);

  // set loading to true when isPending is true
  useEffect(() => {
    if (isPending) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isPending]);

  useEffect(() => {
    startTransition(async () => {
      try {
        const res = await fetch(process.env.NEXT_PUBLIC_URL + '/api/auth/me', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await res.json();

        if (!data || data.error) {
          setUser(null);
          return;
        }

        setUser(data);
      } catch {
        setUser(null);
      }
    });
  }, [router, pathname]);

  return (
    <AuthHandlerContext.Provider value={{ loading, user, signIn, signOut, changePassword }}>
      {children}
    </AuthHandlerContext.Provider>
  );
};

function useAuth(): IAuthHandlerContext {
  const context = useContext(AuthHandlerContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthHandlerProvider');
  }

  return context;
}

export { AuthHandlerProvider, useAuth };
