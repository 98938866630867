'use client';
import { useAuth } from '@/hooks/context/authContext';
import { pathnames } from '@/i18nConfig';
import { usePathname } from '@/navigation';
import {
  FingerPrintIcon,
  UsersIcon,
  ArrowLeftStartOnRectangleIcon,
  ChartBarIcon,
  ChartPieIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/20/solid';
import { useTranslations } from 'next-intl';
import { EnumOrganizationType } from '../models/auth';
import { useCallback, useMemo } from 'react';

interface NavigationItem {
  name?: string;
  href: keyof typeof pathnames;
  color: string;
  hexColor: string;
  altColor: string;
  children?: any;
}

export function useNavigation(): NavigationItem[] {
  const t = useTranslations();
  return [
    {
      name: t('HeaderComponent.indicatorsName'),
      href: '/kpis',
      color: 'kpis-primary',
      hexColor: '#F49717',
      altColor: 'white',
      children: [],
    },
    {
      name: t('HeaderComponent.forecastsName'),
      href: '/previsoes',
      hexColor: '#266DD3',
      color: 'previsoes',
      altColor: 'white',
    },
    {
      name: t('HeaderComponent.insightsName'),
      href: '/percepcoes',
      hexColor: '#CC2136',
      color: 'primary',
      altColor: 'white',
    },
    {
      name: t('HeaderComponent.opinionsName'),
      href: '/opinioes',
      hexColor: '#e09a8e',
      color: 'opinioes',
      altColor: 'white',
    },
    {
      name: t('HeaderComponent.contributionsName'),
      href: '/contributos',
      hexColor: '#e3b636',
      color: 'contributos',
      altColor: 'white',
    },
  ];
}
export function useTextColorClass(customPathname: string, isContrast: boolean = false, hex?: boolean): string {
  const pathname = usePathname() as string;
  const navItems = useNavigation();

  if (customPathname) {
    const customNavItem = navItems.find((item) => customPathname.includes(item.href as string));

    if (hex) {
      return navItems.find((item) => customPathname.includes(item.href as string))?.hexColor || '#CC2136';
    }

    if (isContrast) {
      return customNavItem ? customNavItem.altColor : 'white';
    }
    return customNavItem ? customNavItem.color : 'primary';
  }

  const currentNavItem = navItems.find((item) => pathname.includes(item.href as string));

  if (isContrast) {
    return currentNavItem ? currentNavItem.altColor : 'white';
  }

  if (hex) {
    return navItems.find((item) => pathname.includes(item.href as string))?.hexColor || '#CC2136';
  }

  return currentNavItem ? currentNavItem.color : 'primary';
}

interface IUserNavigation {
  name: string;
  href?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  permissions?: EnumOrganizationType[];
  className?: string;
  onClick?: () => void;
}

export function useAuthNavigation(): IUserNavigation[] {
  const { signOut } = useAuth();
  const t = useTranslations();
  const signOutCallback = useCallback(() => signOut(), [signOut]);

  const translations = useMemo(
    () => ({
      signOut: t('AuthenticationPage.signOut'),
      settings: t('Menu.userMenuSettings'),
      contributionsManagement: t('Menu.userMenuContributionsManagement'),
      nps: t('Menu.userMenuNPS'),
      kpis: t('Menu.userMenuKPIs'),
      predictions: t('Menu.userMenuPredictions'),
    }),
    [t],
  );

  return useMemo(
    () => [
      { name: translations.settings, href: '/painel/configuracoes', icon: FingerPrintIcon },
      {
        name: translations.contributionsManagement,
        href: '/painel/gestao-de-contributos',
        icon: UsersIcon,
        permissions: [EnumOrganizationType.Company],
      },
      {
        name: translations.kpis,
        href: '/painel/company-kpis',
        icon: ChartPieIcon,
        permissions: [EnumOrganizationType.Company],
      },
      {
        name: translations.predictions,
        href: '/painel/company-previsoes',
        icon: PresentationChartLineIcon,
        permissions: [EnumOrganizationType.Company],
      },
      {
        name: translations.nps,
        href: '/painel/company-percepcoes',
        icon: ChartBarIcon,
        permissions: [EnumOrganizationType.Company],
      },
      {
        name: translations.signOut,
        onClick: signOutCallback,
        icon: ArrowLeftStartOnRectangleIcon,
        className: 'bg-primary text-white duration-200 transition-colors',
      },
    ],
    [translations, signOutCallback],
  );
}
